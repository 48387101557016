
import { Component, Watch } from "vue-property-decorator";
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";
import { HospitalData, ReservationData } from "@/@types/reservation";
import MemberService from "@/services/members";
import FacilityService from "@/services/facilities";
import AuthModule from "@/store/auth";
import { authCheck, deleteNullProp } from "@/utils/Util";
import CheckCommonLogin from "@/utils/CheckCommonLogin";
import Calendar from "@/components/Calender.vue";
import ReservationModule from "@/store/reservation";
import moment from "moment";
import _ from "lodash";
import Cognito from "@/plugins/cognito";

@Component({
  name: "MyPageReservationEdit",
  components: {
    Header,
    Footer,
    Calendar,
  },
})
export default class MyPageReservationEdit extends CheckCommonLogin {
  private get me() {
    return AuthModule.me;
  }

  private get selectedDate() {
    return ReservationModule.selectedDate;
  }

  private reservationData: ReservationData | null = null;

  private hospitalInfo: HospitalData | null = null;

  private async getHospitalInfo() {
    try {
      if (this.reservationData) {
        const res = await FacilityService.getHospital(this.reservationData?.facility_id);
        this.hospitalInfo = res.data.data[0];
      }
    } catch (error: any) {
      throw new Error(error);
    }
  }

  private async created() {
    await this.getReservationDetail();
    this.getHospitalInfo();
  }

  private loading = false;
  private submitLoading = false;

  @Watch("selectedDate")
  private confirmModal() {
    if (!this.isChangeable(this.reservationData)) {
      this.showModal("noChange");
      return;
    }
    if (this.selectedDate) {
      this.showModal("confirm");
    } else {
      this.submit();
    }
  }

  private reservationDateFactory(time: string) {
    return moment(new Date(time).toISOString()).format("YYYY/MM/DD(ddd)");
  }
  private reservationTimeFactory(time: string) {
    return moment(new Date(time).toISOString()).format("HH:mm");
  }

  private cancelChange() {
    ReservationModule.changeSelectedDate(null);
    this.$router.push({ name: "MyPage" });
  }

  private isChangeable(reservation: any) {
    const days = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
    let isChangeable = false;
    if (reservation.courseDetail) {
      const reservationDate = moment(new Date(reservation.datetime).toISOString());
      const day = days[reservationDate.get("day")];
      const courseDaysData = reservation.courseDetail ? reservation.courseDetail.days : [];
      const timeObject = courseDaysData.find((value: any) => value.available_days.includes(day));
      if (timeObject) {
        const endMonth = -timeObject.times[0].reception_time.end_month;
        const endDate = -timeObject.times[0].reception_time.end_date;
        const endHour = timeObject.times[0].reception_time.end_hour;
        // item.datetimeの月からendMonthを引いて、endDateも引いて時間はendHourをセットする
        const endReceptionTime = moment(new Date(reservation.datetime))
          .add(endMonth, "month")
          .add(endDate, "day")
          .set("hour", endHour)
          .format("YYYY-MM-DD HH:mm");
        // 現在時刻が受付時間を超えているかどうか
        isChangeable = moment(new Date()).isBefore(moment(endReceptionTime));
      }
      //変更可能かどうか
    }
    return isChangeable;
  }

  private async isChangeableCheck(reservation: any) {
    const days = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
    let isChangeable = false;
    const courseRes = await FacilityService.getHospitalCourseDetail(reservation.facility_id, reservation.course_id);
    const courseDetail = courseRes.data.data[0].details[0];
    if (courseDetail) {
      const reservationDate = moment(new Date(reservation.datetime).toISOString());
      const day = days[reservationDate.get("day")];
      const courseDaysData = courseDetail ? courseDetail.days : [];
      const timeObject = courseDaysData.find((value: any) => value.available_days.includes(day));
      if (timeObject) {
        const endMonth = -timeObject.times[0].reception_time.end_month;
        const endDate = -timeObject.times[0].reception_time.end_date;
        const endHour = timeObject.times[0].reception_time.end_hour;
        // item.datetimeの月からendMonthを引いて、endDateも引いて時間はendHourをセットする
        const endReceptionTime = moment(new Date(reservation.datetime))
          .add(endMonth, "month")
          .add(endDate, "day")
          .set("hour", endHour)
          .format("YYYY-MM-DD HH:mm");
        // 現在時刻が受付時間を超えているかどうか
        isChangeable = moment(new Date()).isBefore(moment(endReceptionTime));
      }
      //変更可能かどうか
    }
    return isChangeable;
  }

  private async getReservationDetail() {
    try {
      this.loading = true;
      const reservationId = this.$route.params.id;
      if (this.me && reservationId) {
        const res = await MemberService.getReservationDetail(this.me.id, reservationId);
        const reservationData = res.data.data[0];
        const hospitalRes = await FacilityService.getHospital(reservationData.facility_id);
        const courseRes = await FacilityService.getHospitalCourseDetail(
          reservationData.facility_id,
          reservationData.course_id
        );
        let petName = "";
        if (reservationData.pet_id) {
          const res = await MemberService.getPet(reservationData.member_id, reservationData.pet_id);
          petName = res.data.data[0].name;
        }
        this.reservationData = {
          ...reservationData,
          hospitalName: hospitalRes.data.data[0].name,
          courseName: courseRes.data.data[0].name,
          courseDetail: courseRes.data.data[0] ? courseRes.data.data[0].details[0] : {},
          petName,
        };
      }
      this.loading = false;
    } catch (error: any) {
      this.loading = false;
      throw new Error(error);
    }
  }

  private async reservationDelete() {
    this.submitLoading = true;
    try {
      const isChangeable = await this.isChangeableCheck(this.reservationData);
      if (!isChangeable) {
        this.closeModal("delete");
        this.showModal("noDelete");
        this.submitLoading = false;
        return;
      }
      if (this.me) await MemberService.deleteReservation(this.me.id, this.$route.params.id);
      this.$router.push({ name: "MyPage" });
    } catch (error: any) {
      this.submitLoading = false;
      throw new Error(error);
    }
  }

  private async submit() {
    try {
      if (this.me) {
        const isMember = await authCheck(this.me.id);
        if (!isMember) {
          Cognito.signOut();
          localStorage.removeItem("vuex");
          this.$router.push({ name: "Login" });
          return;
        }
      } else {
        localStorage.removeItem("vuex");
        this.$router.push({ name: "Login" });
        return;
      }
      this.submitLoading = true;
      if (this.reservationData?.status === "immutable") {
        this.showModal("error");
        this.submitLoading = false;
        return;
      }
      const isChangeable = await this.isChangeableCheck(this.reservationData);
      if (!isChangeable) {
        this.closeModal("confirm");
        this.showModal("noChange");
        this.submitLoading = false;
        return;
      }
      const reservationId = this.$route.params.id;
      const updateReservationData = _.cloneDeep(this.reservationData);
      delete updateReservationData?.hospitalName;
      delete updateReservationData?.courseName;
      delete updateReservationData?.petName;
      if (updateReservationData) {
        if (this.selectedDate) updateReservationData.datetime = moment(this.selectedDate).format();
        const payload = deleteNullProp(updateReservationData);
        delete payload.courseDetail;
        delete payload.status;
        delete payload.update_user;
        if (!payload.memo) payload.memo = "";
        await MemberService.updateReservation(updateReservationData.member_id, reservationId, payload);
      }
      ReservationModule.changeSelectedNumberOfPets(1);
      this.$router.push({ name: "MyPage" });
    } catch (error: any) {
      throw new Error(error);
    }
  }

  private async showModalDelete() {
    if (this.me) {
      const isMember = await authCheck(this.me.id);
      if (!isMember) {
        Cognito.signOut();
        localStorage.removeItem("vuex");
        this.$router.push({ name: "Login" });
        return;
      }
    } else {
      localStorage.removeItem("vuex");
      this.$router.push({ name: "Login" });
      return;
    }
    if (this.reservationData?.status === "immutable") {
      this.showModal("error");
      return;
    }
    if (this.isChangeable(this.reservationData)) {
      this.showModal("delete");
    } else {
      this.showModal("noDelete");
    }
  }

  private showModal(name: string) {
    this.$modal.show(name);
  }

  private closeModal(name: string) {
    this.$modal.hide(name);
  }

  private beforeDestroy() {
    ReservationModule.changeSelectedDate(null);
    ReservationModule.changeSelectedNumberOfPets(1);
  }
}
